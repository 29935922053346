@import url('https://fonts.googleapis.com/css2?family=Mooli&display=swap');



*
{
    /* overflow-x: hidden; */
    /* overflow-y: hidden; */
}
body{
    background-color: black;
}

@tailwind base;
@tailwind components;
@tailwind utilities;